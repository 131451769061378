import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, } from "@mui/material";
import {db, auth, model} from "../App"
import { collection, getDocs, doc, getDoc, setDoc, query, where } from "firebase/firestore";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../assets/logo.png";
import cities from "../utils/india.json"
import Autocomplete,{ usePlacesWidget } from "react-google-autocomplete";



function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}



const Welcome: React.FC = () => {

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [move_1, set_next] = useState<boolean>(false)
  const [username, set_username] = useState<string>("")
  const [email, set_email] = useState<string>("")
  const [interests, set_interests] = useState<any>([])
  const [pin, set_pin] = useState<string>("")
  const [city, set_city] = useState<string>("")
  const [area, set_area] = useState<string>("")
  const [country, set_country] = useState<string>("")
  const [lat, set_lat] = useState<string>("")
  const [lng, set_long] = useState<string>("")
  const [password, set_password] = useState<string>("")
  const [is_popup,set_is_popup] = useState<boolean>(false) //TBA in edit mode
  const [popup_message, set_popup_message] = useState<string>("");
  const [loading_box,set_is_loading] = useState<boolean>(false) //TBA in edit mode
  const [loading_message, set_loading_message] = useState<string>("");
  const [profession, set_profession] = useState<string>("");
  const [goal, set_goal] = useState<string>("");
  const location = useLocation();

  const navigate = useNavigate();

  const [userType, setUserType] = useState<string>("Space Traveller")

  const handleChange = (event: SelectChangeEvent) => {
    setUserType(event.target.value as string);
  };

  useEffect(() => {
    
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    
    window.addEventListener('resize', handleWindowResize);

    console.log('window resied', window.innerWidth)

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  useEffect(() => {

    run()

  },[])

async function fetchPost(){
       
    await getDocs(collection(db, "users"))
        .then((querySnapshot)=>{               
            const newData = querySnapshot.docs
                .map((doc) => ({...doc.data(), id:doc.id }));
            //console.log('dxx',querySnapshot.docs)
        })
   
}



async function run() {

  console.log('runner',cities[0])

  // Provide a prompt that contains text
  try{
      const prompt = goal
      const promptJson = "Give me 5 nice things about " +  cities[0].city + " in the following json format " + `
      {
        "title": ` + goal + `,
        "steps": [
          "1: Do X",
          "2: Do Y",
          "3: Do Z",
          "4: Do W",
          "5: Do V",
          "image":""
        ]
      }
      
     `

      // To generate text output, call generateContent with the text input
      const result = await model.generateContent(promptJson);
      const response = result.response;
      const text = response.text();
      console.log("ANSW", text);
  

  }
  catch(error){
    console.error(error);
  }

}



function getArea(){

  // var urlx = `https://maps.googleapis.com/maps/api/place/autocomplete/json
  // ?input=`+area+`
  // &key=AIzaSyANC3y2BFY8f7eKsR7dHlADmXBsBdRisw0`

  var url2 = `https://maps.googleapis.com/maps/api/place/autocomplete/json
  ?input=bigchill
  &key=AIzaSyANC3y2BFY8f7eKsR7dHlADmXBsBdRisw0`
  
  console.log('getting address')
  fetch(url2)
      .then((response) => response.json())
      .then((response) => {

          console.log("RESP LOX " + JSON.stringify(response, null, 2));

          if(response.status !== "ZERO_RESULTS"){

          }

  }).then(()=>{
    
      
  })
}

async function goNext(){

  navigate("/locations",{state:{location:area}})

}













 if(window.innerWidth > 650){
  return (


    <div style={{margin:0, backgroundSize:"cover",  flexDirection:'column', zIndex:99999, minHeight:'100vh', display:'flex', justifyContent:'center', alignItems:'center', paddingTop:10, paddingLeft:200, paddingRight:200,paddingBottom:200}} >

        <Header/>

    <div style={{display:'flex',justifyContent:'center',alignItems:'center', flexDirection:"column"}}>
    
    <img src={logo} style={{height:100 ,borderRadius:20, marginTop:100, marginBottom:10}}/>

    {/* <p>Badhe kaam ka hai !</p> */}

    <div style={{display:'flex',flexDirection: 'column', marginTop:50,justifyContent:'center',alignItems:'center'}}>
    
        <h4 style={{color:'black'}}>Where do you want to go today ?</h4>
        {/* <TextField placeholder="Type in a location." focused={true} autoFocus={true} onChange={(event)=>set_goal(event.target.value)} style={{width:600, maxWidth:600, marginTop:20, backgroundColor:'white', borderRadius:40}} /> */}
            <Autocomplete
              apiKey={"AIzaSyANC3y2BFY8f7eKsR7dHlADmXBsBdRisw0"}
              style={{ width: "140%", height:60,paddingLeft:40,paddingRight:40, marginTop:20 }}
              onPlaceSelected={(place) => {
                console.log('ptx',place);
                if(place.formatted_address != undefined) {
                  set_area(place.formatted_address)
              }}}
              options={{
                types: ["(regions)"],
              }}
          />;
          
    <div style={{marginTop:2}}>
    </div>
        <Button variant="contained"  style={{backgroundColor:'#000000', width:200, color:'white', marginTop:5}} onClick={()=>goNext()}>Go</Button>
    </div>

    <div style={{display:'flex',flexDirection: 'column', marginTop:50,justifyContent:'center',alignItems:'center'}}>
      
        <h5 style={{marginTop:20,marginBottom:-10}}>Check out these awesome locations</h5>

        <div style={{display:'flex',justifyContent:'flex-start', width:'100%', alignItems:'flex-start', flexDirection:'column', marginTop:20}}>

          {cities.map((val:any, index:any) => {
            console.log('VAL',val);
            return (
              <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', backgroundColor:'white', borderRadius:10, padding:10, margin:10}}>
                <h4 style={{textAlign:'left', color:'black', alignSelf:'flex-start'}}>{val.city}</h4>

                <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>

                {val.localities.map((val:any, index:any) => {
                  return(
                    <Button onClick={()=>navigate("/locations",{state:{location:val}})} style={{textAlign:'center', padding:10, backgroundColor:"black",color:'white', height:90, width:110, borderRadius:5, margin:5}}>
                      <p style={{textAlign:'left', color:'white', fontSize:12}}>{val}</p>
                    </Button>
                  )
                })}
                </div>
              </div>
            )
          })}

        </div>



    </div>
    
    </div>
    </div>

    

);


 }
 else{

  return (



    <div style={{margin:0,backgroundSize:"cover",backgroundPosition:'center',maxWidth:'100%', width:'100%',  flexDirection:'column', backgroundColor:'white', zIndex:99999, height:'100vh', display:'flex', justifyContent:'flex-start', alignItems:'center'}} >

    <Header/>
    <div style={{display:'flex', width:'100%',justifyContent:'center',alignItems:'center', flexDirection:"column", borderColor:'#dadada', marginLeft:0,marginRight:0,padding:20, marginTop:50,borderWidth:1}}>
    
    <img src={logo} style={{height:60 ,borderRadius:20, margin:20, marginTop:40}}/>

    {/* <p>Badhe kaam ka hai !</p> */}

    <div style={{display:'flex',flexDirection: 'column', marginTop:50,justifyContent:'center',alignItems:'center'}}>
    <h4 style={{color:'black', padding:20,marginBottom:-10, textAlign:'center'}}>Where do you want to go today ?</h4>

        {/* <TextField placeholder="Type in a location." focused={true} autoFocus={true} onChange={(event)=>set_goal(event.target.value)} style={{width:600, maxWidth:600, marginTop:20, backgroundColor:'white', borderRadius:40}} /> */}
        <Autocomplete
              apiKey={"AIzaSyANC3y2BFY8f7eKsR7dHlADmXBsBdRisw0"}
              style={{ width: "100%", height:60,paddingLeft:40,paddingRight:40, marginTop:20 }}
              onPlaceSelected={(place) => {
                console.log('ptx',place);
                if(place.formatted_address != undefined) {
                  set_area(place.formatted_address)
              }}}
              options={{
                types: ["(regions)"],
              }}
          />;
          
    <div style={{marginTop:2}}>
    </div>
        <Button variant="contained"  style={{backgroundColor:'#000000', width:200, height:50, color:'white', marginTop:5}} onClick={()=>goNext()}>Go</Button>
    </div>

    </div>
    </div>


);
 }

  
};

export default Welcome;
