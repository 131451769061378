import React, { useState, useEffect } from "react";
import { Button, Card, Grid, TextField, CircularProgress,LinearProgress, Box } from "@mui/material";
import logo from "../assets/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { collection, DocumentData, getDocs } from "firebase/firestore";
import { db, model } from "../App";
import Header from "../components/Header";

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

const Locations: React.FC = () => {

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [email, set_search] = useState<string>("")
  const [articles, set_articles] = useState<any>([])
  const [user_option, set_user_option] = useState<string>("")
  const [selected_area, set_selected_area] = useState<string>("")
  const [response_txt, set_response_txt] = useState<any>([])

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {

    set_selected_area(location.state.location)
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    console.log('window resied', window.innerWidth)

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };

  },[]);

  useEffect(() => {

    run()

  },[])

const area_features = ["Friendliness", "Adventure","Expensiveness","Fun","Crowd"]

async function run() {

  console.log('running')
  let myLoc = location.state.location
  try{
      const promptJson = "Give me a rating out of 5 on friendliness, adventure, expensiveness, fun and crowdedness things about " +  myLoc + " with a description each in the following json format " + `
      {
        "title": ` + myLoc + `,
        "steps": [
          {"rating": "", "description": "The people are very friendly and welcoming"},
          {"rating": "", "description": "The people are very friendly and welcoming"},
          {"rating": "", "description": "The people are very friendly and welcoming"},
          {"rating": "", "description": "The people are very friendly and welcoming"},
          {"rating": "", "description": "The people are very friendly and welcoming"},
        ]
      }
      
     `

      // To generate text output, call generateContent with the text input
      const result = await model.generateContent(promptJson);
      const response = result.response;
      const text = response.text();
      let txt = text.replaceAll("`","").replaceAll("json","")
      console.log("ANSW", JSON.parse(txt).steps);

      set_response_txt(JSON.parse(txt).steps);

  }
  catch(error){
    console.error(error);
  }

}



 if(window.innerWidth > 650){
  return (


    <div style={{margin:0,  flexDirection:'column', zIndex:99999, minHeight:'100vh', display:'flex', justifyContent:'center', alignItems:'center', paddingTop:10, paddingLeft:200, paddingRight:200,paddingBottom:200}} >

        <Header/>

        <h3 style={{marginTop:100}}>{location.state.location}</h3>

        {response_txt.length > 0
          ?
          response_txt.map((item:any,index:any)=>{

              return (
              <div style={{borderStyle:'solid', width:'100%',maxWidth:'100%',borderRadius:10,borderWidth:2, borderColor:'#dadada', padding:10, margin:10}}>
              <h4 style={{fontStyle:'bold'}}>{area_features[index]}</h4>
              <h2>{item.rating}/5</h2>
              <p>{item.description}</p>
              </div>
              )

            })
          :
          <div style={{display:'flex', justifyContent:'flex-start', alignItems:'center', flexDirection:'column', marginTop:50}}>
            <p>Loading...</p>
            <Box sx={{ width: '200%' }}>
              <LinearProgress />
            </Box>
          </div>
          }


        <div style={{ display: "grid", margin:40, gridTemplateColumns: "repeat(3, 1fr)", gridGap: 20 }}>


        </div>

     </div>
    

);


 }
 else{

  return (


    <div style={{margin:0,  flexDirection:'column', zIndex:99999, minHeight:'100vh', display:'flex', justifyContent:'flex-start', alignItems:'center', paddingLeft:20, paddingRight:20,paddingBottom:200, maxWidth:windowSize.innerWidth}} >

    <Header/>

    <div style={{display:'flex', width:'100%',justifyContent:'center',alignItems:'center', flexDirection:"column", borderColor:'#dadada', marginLeft:0,marginRight:0,padding:20, marginTop:50,borderWidth:1, borderStyle:'solid'}}>
    
    <img src={logo} style={{height:60 ,borderRadius:20, margin:20, marginBottom:10}}/>

    <h3>{location.state.location}</h3>

    </div>

      {response_txt.length > 0
      ?
      response_txt.map((item:any,index:any)=>{

          return (
          <div style={{borderStyle:'solid',borderRadius:2,borderWidth:1, borderColor:'black', width:'100%',maxWidth:'100%', padding:10, margin:10}}>
          <h4>{area_features[index]}</h4>
          <p>{item.rating}/5</p>
          <p>{item.description}</p>
          </div>
          )

        })
      :null
      }




 </div>

);
 }

  
};

export default Locations;
