import React, { useState, useEffect } from "react";
import bg from "../assets/networkgif.webp"
import bg2 from "../assets/networkgif2.gif"
import gplay from "../assets/gplay.png"
import appplay from "../assets/appstore.png"



function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

const Home: React.FC = () => {

  const [windowSize, setWindowSize] = useState(getWindowSize());


  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    console.log('window resied', window.innerWidth)

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const goToPlayStore = () =>{

    window.open(
      'https://play.google.com/store/apps/details?id=com.roqnetworks.areaapp&pli=1',
      '_blank' 
    );

  }

  const goToAppStore = () =>{

    window.open(
      'https://apps.apple.com/us/app/the-area-app/id6469103567',
      '_blank' 
    );

  }

 if(window.innerWidth > 650){
  return (


    <div style={{margin:0, padding:0, flexDirection:'column', backgroundColor:'black', zIndex:99999, height:'100vh', display:'flex', justifyContent:'center', alignItems:'center'}} >

    <h1 style={{fontSize:56, color:'white', fontFamily:'Montserrat', fontWeight:700, position:'absolute', top:60}}>Area</h1>

    <h5 style={{color:'white', marginTop:20,position:'absolute', top:125}}>The local network</h5>

    <img src={bg2} style={{height:'auto', width:'45vw', backgroundColor:"#000000", margin:0, marginTop:40, padding:0}}/>

    {/* <h5 style={{color:'white', marginTop:20}}>Download Here</h5> */}

    <h5 style={{marginTop:20, color:'white'}}>Choose Your Platform</h5>

    <div style={{width:'100vw', margin:20, marginTop:-38,padding:10, display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>

    <img src={gplay} onClick={goToPlayStore} style={{cursor:'pointer',height:'auto', width:'10vw', backgroundColor:"#000000", margin:10, marginTop:40, padding:0}}/>

    <img src={appplay} onClick={goToAppStore} style={{cursor:'pointer',height:'auto', width:'10vw', backgroundColor:"#000000", margin:10, marginTop:40, padding:0}}/>

    </div>

    <div style={{width:'100vw', margin:20,padding:10, display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>

      <a style={{margin:10, color:"#a24857"}} href="/privacy">Privacy Policy</a>
      <a style={{margin:10,color:"#a24857"}} href="/contact">Contact Us</a>


    </div>
     </div>
    

);


 }
 else{

  return (


    <div style={{margin:0, padding:0, flexDirection:'column', backgroundColor:'black', zIndex:99999, height:'100vh', display:'flex', justifyContent:'center', alignItems:'center'}} >

    <h1 style={{fontSize:52, fontWeight:700, color:'white', position:'absolute', top:110}}>Area</h1>

    <h5 style={{color:'white', marginTop:20,position:'absolute', top:160}}>The local network</h5>

    <img src={bg2} style={{height:'auto', width:'90vw', backgroundColor:"#000000", margin:0, marginTop:15, padding:0}}/>

    {/* <h5 style={{color:'white', marginTop:20}}>Download here</h5> */}

    <h5 style={{marginTop:29, color:'white'}}>Choose Your Platform</h5>

    <div style={{width:'100vw', margin:10, marginTop:-34,padding:10, display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>

    <img src={gplay} onClick={goToPlayStore} style={{cursor:'pointer',height:'auto', width:'30vw', backgroundColor:"#000000", margin:10, marginTop:40, padding:0}}/>

    <img src={appplay} onClick={goToAppStore} style={{cursor:'pointer',height:'auto', width:'30vw', backgroundColor:"#000000", margin:10, marginTop:40, padding:0}}/>

    </div>
    <div style={{width:'100vw', margin:5,padding:10, display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>

      <a style={{margin:10,color:"#a24857"}} href="/privacy">Privacy Policy</a>
      <a style={{margin:10,color:"#a24857"}} href="/contact">Contact Us</a>

    </div>

     </div>
    

);
 }

  
};

export default Home;
